// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: '0px 20px 0px 24px',
  },
  tableListText: {
    fontWeight: 600,
  },
  mostLeftIconButton: {
    marginLeft: 'auto',
  },
  iconButton: {
    marginLeft: 5,
  },
  columnsMenuRoot: {
    '& .MuiList-root': {
      padding: '12px 4px',
      width: 250,
    },
  },
  columnsMenuTitle: {
    fontWeight: 600,
    padding: '0px 18px 6px',
  },
  columnsMenuItem: {
    display: 'flex',
    padding: '0px 16px 0px 6px',
  },
  columnsMenuText: {
    color: theme.palette.grey[600],
    fontWeight: 400,
  },
}))

export default useStyles