import React from 'react'
import ReactDOM from 'react-dom/client'

// COMPONENTS
import App from './App'

// CONSTANTS
import customTheme from 'constants/customTheme'

// CONTEXTS
import { AllPagesContextProvider } from 'contexts/AllPagesContext'

// MUIS
import { 
  StyledEngineProvider, 
  ThemeProvider, 
} from '@mui/material/styles'

// MUI XS
import { LicenseInfo } from '@mui/x-data-grid-pro'

// STYLES
import './index.css'

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_PRO_LICENSE)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ThemeProvider theme={customTheme}>
    <StyledEngineProvider injectFirst>
      <AllPagesContextProvider>
        <App />
      </AllPagesContextProvider>
    </StyledEngineProvider>
  </ThemeProvider>
)
