import { useEffect } from 'react'

// LEAFLET
import { 
  useMap, 
  useMapEvents, 
} from 'react-leaflet'

const MapController = (props) => {
  const { 
    zoom, 
    setZoom,
  } = props

  const map = useMap()

  const mapEvents = useMapEvents({
    zoomend() {
      setZoom(mapEvents.getZoom())
    },
  })

  useEffect(() => {
    map.setZoom(zoom)
  }, [zoom])

  return null
}

export default MapController