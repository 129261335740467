import * as XLSX from 'xlsx'

// UTILS
import { changeDateFormat } from 'utils/date'

export const exportDeviceListToExcel = (inputDeviceList) => {
  const sheetDeviceList = inputDeviceList.map((item, index) => {
    return {
      'No': index,
      'ID': item.id,
      'Status': item.connected ? 'Online' : 'Offline',
      'IMEI': item.telemetry.ident,
      'Last Updated': changeDateFormat(item.last_active * 1000),
      'Speed': item.telemetry['position.speed'],
      'Direction': item.telemetry['position.direction'],
      'GSM Signal Level (%)': item.telemetry['gsm.signal.level'],
      'Altitude': item.telemetry['position.altitude'],
      'Latitude': item.telemetry['position.latitude'],
      'Longitude': item.telemetry['position.longitude'],
    }
  })

  const sheet = XLSX.utils.json_to_sheet(sheetDeviceList)

  const workBook = XLSX.utils.book_new()

  XLSX.utils.book_append_sheet(workBook, sheet, 'Table')

  XLSX.writeFile(workBook, 'PTPN Device List.xlsx')
}