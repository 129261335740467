import React, { useContext } from 'react'

// ASSETS
import LogoLacak from 'assets/images/logos/lacakio-full.png'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './appBarUseStyles'

const AppBar = () => {
  const classes = useStyles()

  const { appBarTotalDevices } = useContext(AllPagesContext)

  return (
    <MuiAppBar className={`${classes.appBarRoot} zoom`}>
      <Toolbar className={classes.toolbar}>
        {/* COMPANY LOGO */}
        <Box
          component='img'
          src={LogoLacak}
          alt=''
          className={classes.logoCompany}
        />
        
        {/* TOTAL DEVICES TEXT */}
        <Typography 
          variant='body2'
          className={classes.totalDevicesText}
        >
          <span className={classes.textBold}>{appBarTotalDevices}</span> Total Devices
        </Typography>
      </Toolbar>
    </MuiAppBar>
  )
}

export default AppBar