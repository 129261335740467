import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Customs/CustomDataGrid'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'
import FilterTextField from 'components/FilterTextField/FilterTextField'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useGridApiRef } from '@mui/x-data-grid-pro'

// MUI ICONS
import IconArrowUpward from '@mui/icons-material/ArrowUpward'
import IconSort from '@mui/icons-material/Sort'

// STYLES
import useStyles from './dataGridTableUseStyles'

const DataGridTable = (props) => {
  const {
    initialColumns,
    rows,
    total,
    page,
    pageSize,
    setOrder,
    setOrderBy,
    setPage,
    setPageSize,
    setFilters,
    isFilterOn,
    selectedColumnList,
    setSelectedColumnList,
    ...otherProps
  } = props

  const classes = useStyles()

  const dataGridApiRef = useGridApiRef()

  const [sortModel, setSortModel] = useState([])

  const getSortIcon = (field) => {
    const currentSortModel = dataGridApiRef.current.getSortModel()

    let selectedIcon = <IconSort className={classes.columnUnsortedIconAsc} />
    if (currentSortModel[0]) {
      if (currentSortModel[0].field === field) {
        if (currentSortModel[0].sort === 'asc') {
          selectedIcon = (
            <IconArrowUpward
              className={`${classes.columnUnsortedIconAsc} ${classes.columnSortedIconAsc}`}
            />
          )
        } else if (currentSortModel[0].sort === 'desc') {
          selectedIcon = (
            <IconArrowUpward
              className={`${classes.columnUnsortedIconAsc} ${classes.columnSortedIconDesc}`}
            />
          )
        }
      } else {
        if (currentSortModel[0].sort === 'asc') {
          selectedIcon = (
            <IconSort
              className={`${classes.columnUnsortedIconAsc} ${classes.columnUnsortedIconAsc}`}
            />
          )
        } else if (currentSortModel[0].sort === 'desc') {
          selectedIcon = (
            <IconSort
              className={`${classes.columnUnsortedIconAsc} ${classes.columnUnsortedIconDesc}`}
            />
          )
        }
      }
    }
    return selectedIcon
  }

  const handleSortIconClick = (field) => {
    const currentSortModel = dataGridApiRef.current.getSortModel()

    let newSortModel = []
    if (currentSortModel[0]) {
      if (currentSortModel[0].field === field) {
        if (currentSortModel[0].sort === 'asc') {
          newSortModel = [
            {
              field,
              sort: 'desc',
            },
          ]
        } else if (currentSortModel[0].sort === 'desc') {
          newSortModel = []
        }
      } else {
        newSortModel = [
          {
            field,
            sort: 'asc',
          },
        ]
      }
    } else if (!currentSortModel[0]) {
      newSortModel = [
        {
          field,
          sort: 'asc',
        },
      ]
    }

    handleSortModelChange(newSortModel)
  }

  const filterOnColumns = initialColumns.map((item) => {
    if (item.field !== 'actions' && item.areFilterAndSortShown) {
      return {
        renderHeader: (params) => (
          <>
            {/* DEVICE TEXT FIELD */}
            <FilterTextField
              className={classes.columnFilter}
              variant='standard'
              label={item.headerName}
              type='text'
              name={item.field}
              updateFilters={setFilters}
              onClick={(event) => event.stopPropagation()}
            />

            {/* SORT ICON */}
            <CustomTooltipBlack title='Sort by this field' placement='bottom'>
              <IconButton
                size='small'
                onClick={() => handleSortIconClick(item.field)}
              >
                {getSortIcon(item.field)}
              </IconButton>
            </CustomTooltipBlack>
          </>
        ),
      }
    }

    return { renderHeader: null }
  })

  const handleChangeRowsPerPage = (newPageSize) => {
    setPageSize(newPageSize)
    setPage(0)
  }

  const handleSortModelChange = (model, details) => {
    setOrder(model[0] ? model[0].sort : null)
    setOrderBy(model[0] ? model[0].field : null)
    setSortModel(model)
  }

  useEffect(() => {
    if (isFilterOn) {
      setSelectedColumnList((current) => {
        return current.map((item, index) => {
          return {
            ...item,
            renderHeader: filterOnColumns[index].renderHeader
              ? filterOnColumns[index].renderHeader
              : null,
            sortable: false,
          }
        })
      })
    } else {
      setSelectedColumnList((current) => {
        return current.map((item, index) => {
          if (item.field !== 'actions') delete item.sortable
          return { 
            ...item, 
            renderHeader: undefined,
          }
        })
      })
    }
  }, [isFilterOn])

  return (
    <Box className={classes.root}>
      <CustomDataGrid
        // BASE
        columns={selectedColumnList}
        rows={rows}
        headerHeight={isFilterOn ? 72 : 49}
        // PAGINATION
        page={page}
        pageSize={pageSize}
        onPageSizeChange={handleChangeRowsPerPage}
        onPageChange={(page, details) => setPage(page)}
        // paginationMode='server'
        rowCount={total}
        // SORT
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        apiRef={dataGridApiRef}
        // SETTINGS
        initialState={{
          pinnedColumns: {
            right: ['actions'],
          },
        }}
        {...otherProps}
      />
    </Box>
  )
}

DataGridTable.defaultProps = {
  initialColumns: [],
  rows: [],
  total: 0,
  page: 0,
  pageSize: 0,
  isFilterOn: false,
  selectedColumnList: [],
}

DataGridTable.propTypes = {
  initialColumns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setOrder: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  isFilterOn: PropTypes.bool.isRequired,
  selectedColumnList: PropTypes.array.isRequired,
}

export default DataGridTable
