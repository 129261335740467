import React from 'react'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './customClusterUseStyles'

const CustomCluster = (props) => {
  const { cluster } = props

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.innerCircle}>
        <Typography variant='caption'>
          {cluster.getChildCount()}
        </Typography>
      </Box>
    </Box>
  )
}

export default CustomCluster