// PAGES
import Main from 'pages/Main/Main'

function App() {
  return (
    <Main/>
  )
}

export default App
