// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  scrollToTop: {
    position: 'fixed', 
    bottom: 16, 
    right: 16,
  },
}))

export default useStyles