// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    width: '100%',
    // height: '100vh',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: alpha('#FFFFFF', 0.7),
    width: '100%',
    height: '100%',
  },
  loading: {
    width: '48px !important',
    height: '48px !important',
  },
  pageContent: {
    width: '100%',
    // marginTop: 72,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  dataGridContainer: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    // flex: 1,
    // height: 600,
    display: 'flex',
    flexDirection: 'column',
  },
  columnStatus: {
    font: 'inherit',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '&::before': {
      content: '""',
      width: 8,
      height: 8,
      backgroundColor: theme.palette.grey[600],
      borderRadius: '50%',
      marginRight: 8,
    },
  },
  scrollToTopFab: {
    backgroundColor: theme.palette.success.light,
  },
  scrollToTopIcon: {
    color: 'white',
  },
}))

export default useStyles