// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'white',
    borderRadius: 2,
    right: 20,
    bottom: 20,
    '& .MuiButtonGroup-grouped:not(:last-of-type)': {
      borderColor: theme.palette.divider,
    },
  },
  buttonGroupItem: {
    width: 40,
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  icon: {
    color: theme.palette.grey[600],
  },
  iconActive: {
    color: theme.palette.primary.main,
  },
}))

export default useStyles