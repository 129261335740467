import React, { createContext, useState } from 'react'

const AllPagesContext = createContext()

const AllPagesContextProvider = (props) => {
  // APP BAR
  const [ appBarTotalDevices, setAppBarTotalDevices ] = useState(0)

  return (
    <AllPagesContext.Provider
      value={{
        appBarTotalDevices, setAppBarTotalDevices,
      }}
    >
      {props.children}
    </AllPagesContext.Provider>
  )
}

export { AllPagesContextProvider, AllPagesContext }