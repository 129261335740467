// APIS
import axiosFlespi from 'apis/axiosFlespi'

export const getFlespiDeviceList = async (inputFlespiToken) => {
  try {
    const response = await axiosFlespi.get(
      '/gw/devices/all?fields=id%2Cconnected%2Clast_active%2Ctelemetry',
      { 
        headers: {
          'Authorization': `FlespiToken ${inputFlespiToken}`,
          'Content-Type': 'application/json',
        }, 
      },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}