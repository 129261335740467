// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mapRoot: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    // flex: 1,
    height: 600,
  },
  mapContainer: {
    position: 'relative',
    zIndex: 1,
    '&.leaflet-container': {
      width: '100%',
      height: '100%',
    },
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
  },
  textBold: {
    fontWeight: 600,
  },
}))

export default useStyles