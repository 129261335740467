import React, { useState, useEffect, useContext } from 'react'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'
import Map from './Map/Map'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import ScrollToTop from 'components/ScrollToTop/ScrollToTop'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Fab from '@mui/material/Fab'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconKeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'

// SERVICES
import { getFlespiDeviceList } from 'services/flespiDeviceServices'

// STYLES
import useStyles from './mainUseStyles'

// UTILS
import { changeDateFormat } from 'utils/date'
import { exportDeviceListToExcel } from 'utils/mainPageUtil'

const Main = () => {
  const classes = useStyles()

  const { setAppBarTotalDevices }= useContext(AllPagesContext)

  const initialFilters = {
    connected: '',
    'telemetry.ident': '',
    last_active: '',
  }

  const initialColumns = [
    {
      field: 'connected',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => (
        <Typography
          variant='caption'
          className={classes.columnStatus}
          sx={{
            '&::before': {
              backgroundColor: (theme) => params.value 
                ? `${theme.palette.success.main} !important` 
                : `${theme.palette.error.main} !important`,
            },
          }}
        >
          {params.value ? 'Online' : 'Offline'}
        </Typography>
      ),
    },
    {
      field: 'telemetry.battery.level',
      headerName: 'Battery Level (%)',
      flex: 1,
      minWidth: 200,
      hide: false,
      areFilterAndSortShown: true,
      valueGetter: (params) => params.row.telemetry['battery.level'] ? `${params.row.telemetry['battery.level']}%` : '',
    },
    {
      field: 'telemetry.ident',
      headerName: 'IMEI',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
      valueGetter: (params) => params.row.telemetry.ident,
    },
    {
      field: 'last_active',
      headerName: 'Last Updated',
      flex: 1,
      minWidth: 175,
      hide: false,
      areFilterAndSortShown: true,
      valueGetter: (params) => changeDateFormat(params.value * 1000),
    },
    {
      field: 'telemetry.position.speed',
      headerName: 'Speed (km/h)',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      valueGetter: (params) => params.row.telemetry['position.speed'],
    },
    {
      field: 'telemetry.position.direction',
      headerName: 'Direction',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      valueGetter: (params) => params.row.telemetry['position.direction'],
    },
    {
      field: 'telemetry.gsm.signal.level',
      headerName: 'GSM Signal Level (%)',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      valueGetter: (params) => params.row.telemetry['gsm.signal.level'],
    },
    {
      field: 'telemetry.position.altitude',
      headerName: 'Altitude',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      valueGetter: (params) => params.row.telemetry['position.altitude'],
    },
    {
      field: 'telemetry.position.latitude',
      headerName: 'Latitude',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      valueGetter: (params) => params.row.telemetry['position.latitude'],
    },
    {
      field: 'telemetry.position.longitude',
      headerName: 'Longitude',
      flex: 1,
      minWidth: 100,
      hide: false,
      areFilterAndSortShown: false,
      valueGetter: (params) => params.row.telemetry['position.longitude'],
    },
  ]

  const [ isFilterOn, setIsFilterOn ] = useState(false)
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ tableData, setTableData ] = useState([])
  const [ filteredTableData, setFilteredTableData ] = useState([])
  const [ total, setTotal ] = useState(0)
  const [ page, setPage ] = useState(0)
  const [ size, setPageSize ] = useState(100)
  const [ order, setOrder ] = useState(null)
  const [ orderBy, setOrderBy ] = useState(null)
  const [ filters, setFilters ] = useState(initialFilters)
  const [ isLoading, setIsLoading ] = useState(true)

  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide
    setSelectedColumnList(tempSelectedColumnList)
  }

  const loadFlespiDeviceListData = async () => {
    const responseMiniTracker = await getFlespiDeviceList(process.env.REACT_APP_API_FLESPI_TOKEN_MINI_TRACKER)
    const responseGpsTracker = await getFlespiDeviceList(process.env.REACT_APP_API_FLESPI_TOKEN_GPS_TRACKER)

    if(responseMiniTracker.status === 200 && responseGpsTracker.status === 200) {
      let newTableData = responseMiniTracker.data.result.concat(responseGpsTracker.data.result)
      setTableData(newTableData)
      setTotal(newTableData.length)
      setAppBarTotalDevices(newTableData.length)
    }

    setIsLoading(false)
  }

  const updateTableDataByFilters = () => {
    if(tableData.length > 0) {
      const newTableData = tableData.filter(item => {
        const itemStatus = item.connected ? 'online' : 'offline'

        if(
          itemStatus.includes(filters.connected.toLowerCase()) &&
          item.telemetry.ident?.includes(filters['telemetry.ident']) &&
          changeDateFormat(item.last_active * 1000).includes(filters.last_active)
        ) {
          return item
        }
      })

      setFilteredTableData(newTableData)
      setTotal(newTableData.length)
    }
  }

  const handleDownloadIconButtonClick = async () => {
    setIsLoading(true)

    const responseMiniTracker = await getFlespiDeviceList(process.env.REACT_APP_API_FLESPI_TOKEN_MINI_TRACKER)
    const responseGpsTracker = await getFlespiDeviceList(process.env.REACT_APP_API_FLESPI_TOKEN_GPS_TRACKER)
    if(responseMiniTracker.status === 200 && responseGpsTracker.status === 200) {
      let newTableData = responseMiniTracker.data.result.concat(responseGpsTracker.data.result)
      exportDeviceListToExcel(newTableData)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (!isFilterOn) setFilters(initialFilters)
  }, [isFilterOn])

  useEffect(() => {
    loadFlespiDeviceListData()
  }, [])

  useEffect(() => {
    const getDeviceListData = setTimeout(() => {
      loadFlespiDeviceListData()
    }, process.env.REACT_APP_API_API_REQUEST_INTERVAL * 1000)

    return () => clearTimeout(getDeviceListData)
  }, [tableData])

  useEffect(() => {
    updateTableDataByFilters()
  }, [filters])

  return (
    <Box className={`${classes.pageRoot} no-zoom`}>
      {/* LOADING */}
      {isLoading &&
      <Box className={classes.loadingContainer}>
        <CircularProgress className={classes.loading}/>
      </Box>}

      {/* BACK TO TOP TOOLBAR */}
      <Toolbar id='back-to-top-anchor'/>

      {/* APP BAR */}
      <AppBar/>

      {/* PAGE CONTENT */}
      <Box className={`${classes.pageContent} zoom`}>
        {/* MAP */}
        <Map tableData={tableData}/>

        <Box className={classes.dataGridContainer}>
          {/* DATA GRID FILTERS */}
          <DataGridFilters
            columns={initialColumns}
            selectedColumnList={selectedColumnList}
            handleColumnsMenuItemClick={handleColumnsMenuItemClick}
            isFilterOn={isFilterOn}
            setIsFilterOn={setIsFilterOn}
            handleDownloadIconButtonClick={handleDownloadIconButtonClick}
          />
          
          {/* DATA GRID TABLE */}
          <DataGridTable
            initialColumns={initialColumns}
            rows={filteredTableData.length > 0 ? filteredTableData : tableData}
            total={total}
            page={page}
            pageSize={size}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setPage={setPage}
            setPageSize={setPageSize}
            setFilters={setFilters}
            isFilterOn={isFilterOn}
            selectedColumnList={selectedColumnList}
            setSelectedColumnList={setSelectedColumnList}
          />
        </Box>
      </Box>

      {/* SCROLL TO TOP */}
      <ScrollToTop>
        <Fab 
          size='small'
          className={classes.scrollToTopFab}
        >
          <IconKeyboardArrowUp className={classes.scrollToTopIcon}/>
        </Fab>
      </ScrollToTop>
    </Box>
  )
}

export default Main