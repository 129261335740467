// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  appBarRoot: {
    backgroundColor: 'white',
    height: 72,
    zIndex: theme.zIndex.appBar,
  },
  toolbar: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 24px',
    color: theme.palette.text.primary,
  },
  logoCompany: {
    height: 40,
  },
  totalDevicesText: {
    padding: '12px 8px',
    backgroundColor: theme.palette.success.light,
    color: 'white',
    border: `2px solid ${alpha(theme.palette.success.light, 0.3)}`,
    backgroundClip: 'padding-box',
    borderRadius: 2,
  },
  textBold: {
    fontWeight: 700,
  },
}))

export default useStyles