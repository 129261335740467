import React, { useState } from 'react'
import ReactDOMServer from 'react-dom/server'

// COMPONENTS
import CustomCluster from '../CustomCluster/CustomCluster'
import MapController from '../MapController/MapController'
import PanelMenu from '../PanelMenu/PanelMenu'
import PanelZoom from '../PanelZoom/PanelZoom'

// LEAFLET
import { 
  CircleMarker,
  MapContainer, 
  Tooltip,
  TileLayer,
} from 'react-leaflet'
import L from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './mapUseStyles'
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'

// UTILS
import { changeDateFormat } from 'utils/date'

const customClusterMarker = (cluster) => {
  return L.divIcon({
    className: 'marker-cluster-custom',
    html: ReactDOMServer.renderToString(
      <CustomCluster cluster={cluster}/>
    ),
  })
}

const Map = (props) => {
  const { tableData } = props

  const classes = useStyles()

  const minimalZoom = 2
  const maximalZoom = 20

  const mapOptionList = [
    {
      name: 'Google Maps Street',
      url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    },
    {
      name: 'Google Maps Hybrid',
      url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
    },
    {
      name: 'Google Maps Satellite',
      url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    },
  ]

  const [ selectedMapObject, setSelectedMapObject ] = useState(mapOptionList[0])
  const [ zoom, setZoom ] = useState(5)

  let markerData = tableData.filter(item => item.telemetry.position)

  return (
    <Box className={classes.mapRoot}>
      {/* MAP */}
      <MapContainer 
        center={[ -0.7893, 113.9213 ]} 
        className={classes.mapContainer}
        zoomControl={false}
        zoom={zoom}
        minZoom={minimalZoom}
        maxZoom={maximalZoom}
      >
        {/* BASE MAP */}
        <TileLayer
          attribution='&copy; Maps Data: <a href="https://about.google/brand-resource-center/products-and-services/geo-guidelines/#required-attribution">Google</a>'
          url={selectedMapObject.url}
          subdomains={['mt1','mt2','mt3']}
          minZoom={minimalZoom}
          maxZoom={maximalZoom}
          key={selectedMapObject.name}
        />

        {/* MAP CONTROLLER */}
        <MapController 
          zoom={zoom}
          setZoom={setZoom}
        />

        {/* MARKER CLUSTER */}
        <MarkerClusterGroup
          spiderfyDistanceMultiplier={1}
          showCoverageOnHover={false}
          maxClusterRadius={35}
          iconCreateFunction={customClusterMarker}
        >
          {markerData.length > 0 &&
          markerData.map((item, index) => (
            <CircleMarker
              key={index}
              center={[ item.telemetry['position.latitude'], item.telemetry['position.longitude'] ]}
            >
              <Tooltip
                direction='right'
                // offset={[ -8, -2 ]}
                opacity={1}
                className={classes.tooltip}
              >
                {/* IMEI */}
                <Typography
                  variant='caption'
                  className={classes.textBold}
                  color='primary'
                >
                  IMEI: {item.telemetry.ident}
                </Typography>

                {/* LAST UPDATED */}
                <Typography variant='caption'>
                  {changeDateFormat(item.last_active * 1000)}
                </Typography>
              </Tooltip>
            </CircleMarker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>

      {/* MENU PANEL */}
      <PanelMenu
        mapOptionList={mapOptionList}
        selectedMapObject={selectedMapObject}
        setSelectedMapObject={setSelectedMapObject}
      />
      
      {/* ZOOM PANEL */}
      <PanelZoom
        zoom={zoom}
        setZoom={setZoom}
      />
    </Box>
  )
}

export default Map