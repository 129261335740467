import React, { useState } from 'react'
import PropTypes from 'prop-types'

// CUSTOM COMPONENTS
import CustomMenuItem from 'components/Customs/CustomMenuItem'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconFilterAltOutlined from '@mui/icons-material/FilterAltOutlined'
import IconSaveAltOutlined from '@mui/icons-material/SaveAltOutlined'
import IconSettingsOutlined from '@mui/icons-material/SettingsOutlined'

// STYLES
import useStyles from './dataGridFiltersUseStyles'

const DataGridFilters = (props) => {
  const { 
    columns,
    selectedColumnList,
    handleColumnsMenuItemClick,
    isFilterOn, 
    setIsFilterOn,
    handleDownloadIconButtonClick,
  } = props

  const classes = useStyles()

  const [ columnsMenuAnchor, setColumnsMenuAnchor ] = useState(null)

  return (
    <Box className={classes.root}>
      {/* TABLE LIST TEXT */}
      <Typography
        variant='subtitle1'
        className={classes.tableListText}
      >
        Table List
      </Typography>

      {/* DOWNLOAD ICON */}
      <CustomTooltipBlack 
        title='Download' 
        placement='top'
      >
        <IconButton 
          className={classes.mostLeftIconButton}
          onClick={handleDownloadIconButtonClick}
        >
          <IconSaveAltOutlined color={isFilterOn ? 'primary' : ''}/>
        </IconButton>
      </CustomTooltipBlack>

      {/* FILTER ICON */}
      <CustomTooltipBlack 
        title='Filter' 
        placement='top'
      >
        <IconButton 
          className={classes.iconButton}
          onClick={() => setIsFilterOn(current => !current)}
        >
          <IconFilterAltOutlined color={isFilterOn ? 'primary' : ''}/>
        </IconButton>
      </CustomTooltipBlack>

      {/* CUSTOMIZE COLUMNS ICON */}
      <CustomTooltipBlack 
        title='Customize Columns' 
        placement='top'
      >
        <IconButton 
          className={classes.iconButton}
          onClick={(event) => setColumnsMenuAnchor(event.currentTarget)}
        >
          <IconSettingsOutlined/>
        </IconButton>
      </CustomTooltipBlack>

      {/* CUSTOMIZE COLUMNS MENU */}
      <Menu
        anchorEl={columnsMenuAnchor}
        open={Boolean(columnsMenuAnchor)}
        onClose={() => setColumnsMenuAnchor(null)}
        className={classes.columnsMenuRoot}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* TITLE */}
        <Typography
          variant='subtitle2'
          className={classes.columnsMenuTitle}
        >
          Columns
        </Typography>

        {/* COLUMN ITEMS */}
        {columns.map((item, index) => (
          (item.field !== 'actions') &&
          <CustomMenuItem 
            key={index}
            className={classes.columnsMenuItem}
            onClick={() => handleColumnsMenuItemClick(item, index)}
          >
            <Checkbox checked={!selectedColumnList[index].hide}/>
            <Typography
              variant='subtitle2'
              className={classes.columnsMenuText}
            >
              {item.headerName}
            </Typography>
          </CustomMenuItem>
        ))}
      </Menu>
    </Box>
  )
}

DataGridFilters.defaultProps = {
  columns: [],
  selectedColumnList: [],
  isFilterOn: false,
}

DataGridFilters.propTypes = {
  columns: PropTypes.array.isRequired,
  selectedColumnList: PropTypes.array.isRequired,
  handleColumnsMenuItemClick: PropTypes.func.isRequired,
  isFilterOn: PropTypes.bool.isRequired,
  setIsFilterOn: PropTypes.func.isRequired,
  handleDownloadIconButtonClick: PropTypes.func.isRequired,
}

export default DataGridFilters
