import React, { useState } from 'react'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Menu from '@mui/material/Menu'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import IconMap from '@mui/icons-material/Map'

// STYLES
import useStyles from './panelMenuUseStyles'

const PanelMenu = (props) => {
  const {
    mapOptionList,
    selectedMapObject,
    setSelectedMapObject,
  } = props

  const classes = useStyles()

  const [ mapMenuAnchor, setMapMenuAnchor ] = useState(null)

  const handleMenuAnchorClose = (event, reason, key) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      if(key === 'map') setMapMenuAnchor(false)
    }
  }

  return (
    <>
      {/* CONTROL BUTTON GROUP PANEL */}
      <ButtonGroup 
        className={classes.panelContainer}
        variant='contained'
      >
        {/* MAP BUTTON */}
        <CustomTooltipBlack
          title='Map'
          placement='bottom'
        >
          <Button 
            className={classes.buttonGroupItem}
            variant='text'
            onClick={(event) => setMapMenuAnchor(event.currentTarget)}
          >
            <IconMap className={Boolean(mapMenuAnchor) ? classes.iconActive : classes.icon}/>
          </Button>
        </CustomTooltipBlack>
      </ButtonGroup>

      {/* MAP MENU */}
      <Menu
        anchorEl={mapMenuAnchor}
        open={Boolean(mapMenuAnchor)}
        onClose={(event, reason) => handleMenuAnchorClose(event, reason, 'map')}
        className={classes.mapMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* MENU HEADER */}
        <Box className={classes.mapMenuHeader}>
          {/* ICON */}
          <IconMap className={classes.icon}/>

          {/* TITLE */}
          <Typography
            variant='h6'
            className={classes.mapMenuHeaderTitle}
          >
            Map
          </Typography>

          {/* CLOSE ICON */}
          <IconButton onClick={() => setMapMenuAnchor(null)}>
            <IconClose className={classes.icon}/>
          </IconButton>
        </Box>

        <Divider/>

        {/* MAP OPTION LIST */}
        <List className={classes.mapOptionList}>
          {mapOptionList.map((item, index) => (
            <ListItem
              key={index}
              className={classes.mapOptionItem}
            >
              <ListItemButton 
                className={classes.mapOptionItemButton}
                onClick={() => setSelectedMapObject(item)}
              >
                {/* RADIO */}
                <Radio
                  checked={selectedMapObject.name === item.name}
                  onChange={() => setSelectedMapObject(item)}
                  value={item.name}
                  name='radio-buttons'
                />

                {/* TEXT */}
                <Typography variant='body2'>
                  {item.name}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Menu>
    </>
  )
}

export default PanelMenu