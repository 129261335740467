import React from 'react'

// MUIS
import Box from '@mui/material/Box'
import Zoom from '@mui/material/Zoom'
import useScrollTrigger from '@mui/material/useScrollTrigger'

// STYLES
import useStyles from './scrollToTopUseStyles'

const ScrollToTop = (props) => {
  const { 
    children, 
    window, 
  } = props

  const classes = useStyles()
  
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    )

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role='presentation'
        className={classes.scrollToTop}
      >
        {children}
      </Box>
    </Zoom>
  )
}

export default ScrollToTop